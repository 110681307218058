var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 w-full pb-6 space-y-4"},[_c('div',{staticClass:"flex flex-row justify-between text-xs"},[_c('div',{staticClass:"flex flex-row space-x-2"},_vm._l((_vm.allCategories),function(category){return _c('div',{key:category.category,staticClass:"flex flex-row items-center space-x-1"},[_c('span',{staticClass:"w-3 h-3 rounded-full",class:[
                        {
                            'bg-purple-600 ': category.colour === 'purple',
                            'bg-blue-600  ': category.colour === 'blue',
                            'bg-green-600 ': category.colour === 'green',
                            'bg-orange-600 ': category.colour === 'orange',
                            'bg-indigo-600 ': category.colour === 'indigo',
                        } ]}),(_vm.BlockCategory.MachineLearning === category.category)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(category.label),expression:"category.label"}],staticClass:"uppercase whitespace-nowrap"},[_vm._v(_vm._s(category.category))]):_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(category.label))])])}),0)]),_c('ul',{staticClass:"flex flex-col w-full space-y-2"},_vm._l((_vm.tasks),function(task){return _c('li',{key:task.id,staticClass:"cursor-pointer"},[_c('TableViewTask',{attrs:{"task":task,"selected":_vm.selected !== null && _vm.selected.id === task.id,"highlighted":_vm.isHighlighted(task),"columns":task.id ? _vm.columnsPerTask[task.id] : null,"runningExecution":_vm.runningExecution,"validationInfo":_vm.validationInfo(task),"failureMessage":_vm.getFailureMessage(task)},on:{"select":_vm.showSettings}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }